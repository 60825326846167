// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-coursework-js": () => import("./../src/pages/coursework.js" /* webpackChunkName: "component---src-pages-coursework-js" */),
  "component---src-pages-drawings-js": () => import("./../src/pages/drawings.js" /* webpackChunkName: "component---src-pages-drawings-js" */),
  "component---src-pages-grande-disco-js": () => import("./../src/pages/grandeDisco.js" /* webpackChunkName: "component---src-pages-grande-disco-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sculpture-js": () => import("./../src/pages/sculpture.js" /* webpackChunkName: "component---src-pages-sculpture-js" */),
  "component---src-pages-thoughts-js": () => import("./../src/pages/thoughts.js" /* webpackChunkName: "component---src-pages-thoughts-js" */),
  "component---src-pages-web-js": () => import("./../src/pages/web.js" /* webpackChunkName: "component---src-pages-web-js" */)
}

